import React from 'react';
import Button from '../../components/Button';
import { FaLinkedin, FaRegFileAlt } from 'react-icons/fa';
import Typing from 'react-typing-animation';
import Cursor from "../../components/Cursor";

export default function WelcomePage() {
    return (
      <>
          <Typing speed={50} startDelay={8000} cursor={<Cursor />}>
              <h1>Hey there!</h1>
              <Typing.Delay ms={1500} />
              <Typing.Speed ms={0.1} />
              <p><span>Thank you for checking out my personal website! It's still under development but you can enjoy the nice animation I created for now...</span></p>
              <p><span>What else would you like to do?</span></p>
              <p>
                  <Button link={"https://linkedin.com/in/letadeos"} target={"_blank"}>
                      <FaLinkedin /> Add on linkedin
                  </Button>
                  <Button link="/statics/Luis_E_Tadeo_Resume_Nov_2019.pdf" target={"_blank"}>
                      <FaRegFileAlt /> View PDF resume
                  </Button>
              </p>
          </Typing>

      </>
    );
}