import { combineReducers, AnyAction, Reducer } from 'redux';
import {actionTypes as AppStateActions} from "../actions/appStateActions";

export interface AppState {
    showBackgroundAnimation: boolean
}

const appStateReducer : Reducer = (state: AppState, action: AnyAction) : AppState => {
    switch(action.type) {
        case AppStateActions.TOGGLE_SHOW_BG_ANIMATION: {
            state.showBackgroundAnimation = !state.showBackgroundAnimation;
            return {...state};
        }
        default: return state || {};
    }
};


export interface StoreState {
    appState: AppState
};

const mainReducer : Reducer = combineReducers({
    appState: appStateReducer
});

export default mainReducer;
