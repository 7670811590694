import {createStore} from 'redux';
import mainReducer, {AppState} from "./reducers";

const appState: AppState = {
    showBackgroundAnimation: true
};

const store = createStore(
    mainReducer,
    {appState},
    // use applyMiddleware and compose to add more middleware
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;