import React from "react";
import {CSSTransition} from 'react-transition-group';
import ProfilePicture from "../../components/ProfilePicture";
import styles from "./styles.module.scss";
import animateTitle from '../../animations/flipDown.module.scss';
import animateName from './animateName.module.scss';
import animateProfile from './animateProfilepic.module.scss';

export default function Header() {
    return (
        <header className={styles.header}>
            <CSSTransition appear={true} in={true} timeout={2100} classNames={{...animateProfile}}>
                <ProfilePicture />
            </CSSTransition>
            <div className={styles.content}>
                <CSSTransition appear={true} in={true} timeout={2100} classNames={{...animateName}}>
                    <h2>LUIS TADEO</h2>
                </CSSTransition>
                <CSSTransition appear={true} in={true} timeout={2600} classNames={{...animateTitle}}>
                    <h3>Software Engineer</h3>
                </CSSTransition>
            </div>
        </header>
    );
}