import React from 'react';
import styles from "./styles.module.scss";


export default function ProfilePicture() {
    return (
        <div className={styles.profilePic}>
            <img src={"/statics/profilePic.jpg"} alt={"Luis Tadeo"} />
        </div>
    );
}