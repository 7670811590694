import React from 'react';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { createBrowserHistory } from "history";
import {IconContext} from 'react-icons';

import './index.css';
import App from './App';
import store from './redux/store';

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <Router history={history}>
              <IconContext.Provider value={{
                  style: {
                      verticalAlign: "text-top"
                  }
              }}>
                  <App />
              </IconContext.Provider>
          </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
