import React, {useEffect, useState} from 'react';
import './global.scss';
import styles from './App.module.scss';
import Background from './backgrounds/GeometricWaveBackground';
import WelcomePage from "./pages/welcome";
import Header from "./layout/header";
import {connect} from "react-redux";
import {StoreState} from "./redux/reducers";

type Props = {
    showBackground: boolean
};

function App(props: Props) {
  const {showBackground} = props;
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
      setTimeout(()=>setShowHeader(true), 2500);
  },[]);
  return (
    <div>
      {showBackground && <Background />}
      <div className={styles.appContainer}>
          <div className={styles.header}>
              {showHeader && <Header />}
          </div>
          <div className={styles.pageWrapper}>
              <div className={styles.pageContainer}>
                  <WelcomePage />
              </div>
          </div>
      </div>
    </div>
  );
}

export default connect(
    (state: StoreState) => ({
        showBackground: state.appState.showBackgroundAnimation
    })
)(App);
