import React, {useEffect, useRef} from 'react';
import styles from './Background.module.scss';
import * as Waves from '../waveUtils';
import * as THREE from 'three';
import * as d3 from 'd3-ease';
import {computeWaves} from "../waveUtils";

let size: number = 80,
    amplitude: number = 0.8,
    scene: THREE.Scene,
    camera: THREE.PerspectiveCamera,
    ratio = () => window.innerWidth / window.innerHeight,
    cameraStartPos = new THREE.Vector3(0, 0,size/4),
    cameraEndPos = new THREE.Vector3(0, -size/30,size/4 - 10),
    renderer: THREE.WebGLRenderer = new THREE.WebGLRenderer(),
    geometry: THREE.BufferGeometry,
    triangles: number[],
    ambient: THREE.AmbientLight,
    light: THREE.PointLight,
    ambientIntensity: number = 0.2,
    lightIntensity: number = 0,
    deltaLightIntensity = 1.5,
    startLookAt= new THREE.Vector3(0,-size/20,0),
    endLookAt= new THREE.Vector3(0,size/15,2);

function init() {
    setUpScene();
    triangles = Waves.generateTriangles(size,75, 0.08);
}

function setUpScene(reload?: boolean) {
    camera = new THREE.PerspectiveCamera( 75, ratio(), 0.1, 100 );
    scene = new THREE.Scene();
    const {x,y,z} = reload ? cameraEndPos : cameraStartPos;
    camera.position.set(x,y,z);
    camera.lookAt(reload ? endLookAt : startLookAt);

    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setClearColor(0x0b1620, 1);

    geometry = new THREE.BufferGeometry();
    const mesh = new THREE.Mesh( geometry, new THREE.MeshPhongMaterial({
        color: 0x183444
    }));
    scene.add( mesh );

    light = new THREE.PointLight(0xffffff, lightIntensity );
    light.position.set(0,size,50);
    scene.add(light);

    ambient = new THREE.AmbientLight(0xffffff, ambientIntensity);
    scene.add(ambient);
    scene.fog = new THREE.Fog(0x0b1620, 0, size/2);
}

const handleScreenResize = ()=> {
    setUpScene(true);
    renderer.setSize( window.innerWidth, window.innerHeight );
};

let [mx, my] = [0,0],
    [cx,cy] = [mx,my],
    trackMouse = false;
function handleMouse(e: any) {
    let width = window.innerWidth;
    let height = window.innerHeight;
    mx = ((e.clientX || mx) - width/2)/width;
    my = ((e.clientY || my) - height/2)/height;
};

function handleScroll() {
    let top = window.scrollY/window.innerHeight;
    light.intensity = lightIntensity - lightIntensity * top * top * 0.6;
    ambient.intensity = ambientIntensity * (1-d3.easeQuad(top));
};

function generatePolygons() {
    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute(computeWaves(triangles, amplitude), 3));
    geometry.computeVertexNormals();
}

function moveCamera() {
    if(!trackMouse) {
        return;
    }
    const ccpos = cameraEndPos.clone();
    const qy = new THREE.Quaternion();
    qy.setFromAxisAngle(new THREE.Vector3(0,1,0), cx);
    const qx = new THREE.Quaternion();
    qx.setFromAxisAngle(new THREE.Vector3(1,0,0), 0.5 * cy);
    ccpos.applyQuaternion(qx);
    ccpos.applyQuaternion(qy);
    cx += (mx - cx)/50;
    cy += (my - cy)/50;

    camera.position.x = ccpos.x;
    camera.position.y = ccpos.y;
    camera.position.z = ccpos.z;
}

function turnOnLights(duration: number) {
    let ts = Date.now();
    const finalLightIntensity = lightIntensity + deltaLightIntensity;
    let requestId:number;
    let remaining = duration;
    doTurnOnLights();

    function doTurnOnLights() {
        const ct = Date.now();
        const delta = ct - ts;
        const fpms = 1/delta;
        const frames = fpms * duration;
        if(finalLightIntensity > lightIntensity) {
            lightIntensity += (deltaLightIntensity/frames) * d3.easeQuadIn(1-(remaining/duration));
            light.intensity = lightIntensity;
            requestId = requestAnimationFrame(doTurnOnLights);
        } else {
            cancelAnimationFrame(requestId);
        }
        remaining = Math.max(remaining - delta, 0);
        ts = ct;
    }
}

function animateCameraIntoPlace(duration: number) {
    let ts = Date.now();
    const travelVector = cameraEndPos.clone().sub(cameraStartPos);
    const lookAtVector = endLookAt.clone().sub(startLookAt);
    let currentPos = camera.position.clone();
    let currentLookAt = startLookAt.clone();
    let elapsedTime = 0;
    let requestId = requestAnimationFrame(doAnimateCameraIntoPlace);
    turnOnLights(duration/2);

    function doAnimateCameraIntoPlace() {
        const ct = Date.now();
        const delta = ct - ts;
        elapsedTime += delta;
        ts = ct;
        const fpms = 1/delta;
        const frames = duration * fpms;
        const ease = d3.easeQuadInOut(elapsedTime/duration);
        const step = travelVector.clone().multiplyScalar(ease/frames);
        const stepLookAt = lookAtVector.clone().multiplyScalar(ease/frames);
        const ncpos = currentPos.clone().add(step);
        const nlookat = currentLookAt.clone().add(stepLookAt);

        if(cameraEndPos.distanceTo(currentPos) > cameraEndPos.distanceTo(ncpos)) {
            currentPos = ncpos;
            currentLookAt = nlookat;
            let {x,y,z} = currentPos;
            camera.position.x = x;
            camera.position.y = y;
            camera.position.z = z;
            camera.lookAt(nlookat);
            requestId = requestAnimationFrame(doAnimateCameraIntoPlace);
        } else {
            trackMouse = true;
            cancelAnimationFrame(requestId);
        }
    }

}

function animate() : number {
    const id = requestAnimationFrame( animate );
    generatePolygons();
    moveCamera();
    renderer.render( scene, camera );
    return id;
}

export default function GeometricWaveBackground() : React.FunctionComponentElement<any> {

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        init();
        let animationId: number;
        if(ref?.current) {
            ref.current.appendChild(renderer.domElement);
            animationId = animate();
            animateCameraIntoPlace(5000);
        }
        return () => {
            if(animationId) {
                cancelAnimationFrame(animationId);
            }
        }
    },[]);

    useEffect(() => {
        window.addEventListener('resize', handleScreenResize);
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('mousemove', handleMouse);
        handleScreenResize();
        return () => {
            window.removeEventListener('mousemove', handleMouse);
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize',handleScreenResize);
        }
    }, []);

    return (
        <div className={styles.background} ref={ref}/>
    );
}